import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useRecoilState } from 'recoil'
import { STORAGE_TOKEN_KEY } from '../api/constant'
import { dataVitalSignState, fetchDataVitalSignNew } from '../store'

export default function useFetchDataVitalSign(medicalID, setLoading) {
  const [dataVitalSign, setDataVitalSign] = useRecoilState(dataVitalSignState)

  const history = useHistory()

  useEffect(() => {
    let isApiSubscribed = true
    if (isApiSubscribed) {
      setLoading(true)
      fetchDataVitalSignNew(medicalID, 'page=1&orderBy=createdAt&orderDir=desc', (data, err) => {
        if (data) {
          setDataVitalSign(data)
          setLoading(false)
        } else {
          if (err.redirect) {
            localStorage.removeItem(STORAGE_TOKEN_KEY)
            history.push('/login')
            setLoading(false)
          }
        }
      })
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [medicalID, setDataVitalSign, history, setLoading])

  return { dataVitalSign, setDataVitalSign }
}
