import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch registration
export const dataRegistrationState = atom({
  key: 'dataRegistrationState',
  default: { rows: [], meta: {} },
})

export const fetchDataRegistration = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`registrations?${params}`)
    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
