import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch patients
export const dataVitalSign = atom({
  key: 'dataVitalSign',
  default: { rows: [], meta: {} },
})

export const dataSoap = atom({
  key: 'dataSoap',
  default: { rows: [], meta: {} },
})

export const dataCurrentHealtConcern = atom({
  key: 'dataCurrentHealtConcern',
  default: { rows: [], meta: {} },
})

export const dataAllergies = atom({
  key: 'dataAllergies',
  default: { rows: [], meta: {} },
})

export const dataBirthChilhood = atom({
  key: 'dataBirthChilhood',
  default: { rows: [], meta: {} },
})

export const dataDentalHistory = atom({
  key: 'dataDentalHistory',
  default: { rows: [], meta: {} },
})

export const dataEnviromental = atom({
  key: 'dataEnviromental',
  default: { rows: [], meta: {} },
})

export const dataMenWomenHistory = atom({
  key: 'dataMenWomenHistory',
  default: { rows: [], meta: {} },
})

export const dataFamilyHistory = atom({
  key: 'dataFamilyHistory',
  default: { rows: [], meta: {} },
})

export const dataDiagnosticStudies = atom({
  key: 'dataDiagnosticStudies',
  default: { rows: [], meta: {} },
})

export const dataInjuriesSurgeries = atom({
  key: 'dataInjuriesSurgeries',
  default: { rows: [], meta: {} },
})

export const dataAntecedent = atom({
  key: 'dataAntecedent',
  default: { rows: [], meta: {} },
})

export const dataTriggers = atom({
  key: 'dataTriggers',
  default: { rows: [], meta: {} },
})

export const dataMediators = atom({
  key: 'dataMediators',
  default: { rows: [], meta: {} },
})

export const dataSleepRelax = atom({
  key: 'dataSleepRelax',
  default: { rows: [], meta: {} },
})

export const dataExcerciseMovement = atom({
  key: 'dataExcerciseMovement',
  default: { rows: [], meta: {} },
})

export const dataNutrition = atom({
  key: 'dataNutrition',
  default: { rows: [], meta: {} },
})

export const dataStress = atom({
  key: 'dataStress',
  default: { rows: [], meta: {} },
})

export const dataRelationship = atom({
  key: 'dataRelationship',
  default: { rows: [], meta: {} },
})

export const dataSymptomReview = atom({
  key: 'dataSymptomReview',
  default: { rows: [], meta: {} },
})

export const dataSupplement = atom({
  key: 'dataSupplement',
  default: { rows: [], meta: {} },
})

export const dataMedications = atom({
  key: 'dataMedications',
  default: { rows: [], meta: {} },
})

export const dataPhysicalExamnations = atom({
  key: 'dataPhysicalExamnations',
  default: { rows: [], meta: {} },
})

export const dataDifferentialDiagnosis = atom({
  key: 'dataDifferentialDiagnosis',
  default: { rows: [], meta: {} },
})

export const dataClinicalIndicatorState = atom({
  key: 'dataClinicalIndicatorState',
  default: { rows: [], meta: {} },
})

export const dataAnthropometicsState = atom({
  key: 'dataAnthropometicsState',
  default: { rows: [], meta: {} },
})

export const dataNeuroExamState = atom({
  key: 'dataNeuroExamState',
  default: { rows: [], meta: {} },
})

export const dataMSQState = atom({
  key: 'dataMSQState',
  default: null,
})

export const fetchDataMSQ = async (medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`msq/${medicalId}`)
    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const createMSQ = async (input, medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`msq/${medicalId}`, input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataVitalSign = async (params, medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`vitalsigns/${medicalId}?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataSOAP = async (medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`doctorsoaps/records/${medicalId}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataMedicalRecord = async (medicalRecordType, params, medicalId, cb) => {
  try {
    const { request } = API(true)
    // page=1&size=10&orderBy=createdAt&orderDir=desc&search
    const { data } = await request.get(`mrforms/${medicalRecordType}/${medicalId}?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const deactiveDataDataSOAP = async (id, cb) => {
  try {
    const { request } = API(true)
    // page=1&size=10&orderBy=createdAt&orderDir=desc&search
    const { data } = await request.patch(`doctorsoaps/deactivate/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const deactiveDataMedicalRecord = async (medicalRecordType, medicalId, cb) => {
  try {
    const { request } = API(true)
    // page=1&size=10&orderBy=createdAt&orderDir=desc&search
    const { data } = await request.patch(`mrforms/${medicalRecordType}/${medicalId}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const createMedicalRecord = async (medicalRecordType, input, medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`mrforms/${medicalRecordType}/${medicalId}`, input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const createDoctorSOAP = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`/doctorsoaps/newrecord`, input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataDetailMedicalRecord = async (medicalRecordType, recordId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`mrforms/${medicalRecordType}/detail/${recordId}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchClinicalStatuses = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('diagnostics/clinical-status/list')
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchICD10s = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('diagnostics/icd10/list')
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
