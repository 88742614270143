import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch feedbacks
export const dataBodyMeasurementState = atom({
  key: 'dataBodyMeasurementState',
  default: { rows: [], meta: {} },
})
export const fetchDataBodyMeasurement = async (medicalId, params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`bodymeasurements/${medicalId}?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchDataBodyMeasurementLatest = async (medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`bodymeasurements/latest/${medicalId}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// create vitalsign
export const createBodyMeasurement = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('bodymeasurements', input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
