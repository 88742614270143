import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch data Appointment all
export const dataAppointmentAllState = atom({
  key: 'dataAppointmentAllState',
  default: { rows: [], meta: {} },
})

// fetch data Appointment all
export const dataNextAppointmentState = atom({
  key: 'dataNextAppointmentState',
  default: { rows: [], meta: {} },
})

export const fetchDataNextAppointment = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`appointments/upcoming?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const fetchDataAppointmentAll = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`appointments?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch data Appointment types list
export const dataAppointmentTypesState = atom({
  key: 'dataAppointmentTypesListState',
  default: [],
})

export const fetchDataAppointmentListTypes = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`appointments/types/list`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch data appointment show

export const dataAppointmentShowState = atom({
  key: 'dataAppointmentShowState',
  default: [],
})

export const dataCategoryListState = atom({
  key: 'dataCategoryListState',
  default: [],
})

export const dataTreatmentListState = atom({
  key: 'dataTreatmentListState',
  default: [],
})

export const dataPracticianListState = atom({
  key: 'dataPracticianListState',
  default: [],
})

export const fetchDataAppointmentShow = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`appointments/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch data AR manager need approval all
export const dataARNeedApprovalState = atom({
  key: 'dataARNeedApprovalState',
  default: { rows: [], meta: {} },
})

export const fetchARNeedApproval = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`appointments/approval?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch data AR manager history all
export const dataARHistoryState = atom({
  key: 'dataARHistoryState',
  default: { rows: [], meta: {} },
})

export const fetchARHistory = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`appointments/approval/history?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch data AR manager show detail approval
export const dataARShowDetail = atom({
  key: 'dataARShowDetail',
  default: {},
})

export const fetchARShowDetail = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`appointments/approval/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch data Appointment types list
export const dataAppointmentTreatmentParentListState = atom({
  key: 'dataAppointmentTreatmentParentListState',
  default: [],
})

export const fetchDataAppointmentTreatmentParentList = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`treatments/parent?branch`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch data Appointment types list
export const dataAppointmentTreatmentListState = atom({
  key: 'dataAppointmentTreatmentListState',
  default: [],
})

export const fetchDataAppointmentTreatmentList = async (branch = '', chargeParent = '', cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(
      `treatments/list?branch=${branch}&chargeParent=${chargeParent}`,
    )

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch data Appointment Category list
export const dataAppointmentCategoryListState = atom({
  key: 'dataAppointmentCategoryListState',
  default: [],
})

export const fetchDataAppointmentCategoryList = async (branch, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`treatments/parent?branch=${branch}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// create data Appointment
export const createDataAppointment = async (payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`appointments`, payload)

    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else if (error.response.status === 422) {
        cb(null, { message: error.response.data.errors[0].msg })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// update data Appointment / reschedule
export const updateDataAppointment = async (id, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`appointments/${id}`, payload)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// update data appointment / confirm
export const updateDataAppointmentConfirm = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`appointments/confirm/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// update data Appointment / accepted
export const updateDataAppointmentAccept = async (id, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`appointments/register/${id}`, payload)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else if (error.response.status === 422) {
        cb(null, { code: 422, message: error.response.data.errors[0].msg })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// update data Appointment / reject
export const updateDataAppointmentReject = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`appointments/cancel/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

// update data AR Appointment / reject
export const updateDataARReject = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`appointments/approval/reject/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}
// update data AR Appointment / Approve
export const updateDataARApprove = async (id, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`appointments/approval/approve/${id}`, payload)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const appointmentRequestState = atom({
  key: 'appointmentRequestState',
  default: { rows: [], meta: {} },
})
export const fetchAppointmentRequest = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`appointments/appointment-request/list?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const cancleAppointmentRequest = async (id, payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`appointments/appointment-request/cancel/${id}`, payload)
    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}

export const fetchPayerPatient = async (medicalId, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`payers/get-payer/${medicalId.length > 0 ? medicalId : 0}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message)
        // window.location.reload()
      }
    } else {
      alert(error.message)
    }
  }
}
