import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

export const dataCorporateList = atom({
  key: 'dataCorporateList',
  default: [],
})

export const fetchDataCorporateList = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`corporates/list`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
