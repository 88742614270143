import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch my assigning
export const myAssigningState = atom({
  key: 'myAssigningState',
  default: { rows: [], meta: {} },
})
export const fetchMyAssigning = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`assign-doctor/my-assigning?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch my assigned
export const myAssignedState = atom({
  key: 'myAssignedState',
  default: { rows: [], meta: {} },
})
export const fetchMyAssigned = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`assign-doctor/my-assigned?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch list my dpjp
export const myDPJPState = atom({
  key: 'myDPJPState',
  default: [],
})
export const fetchMyDPJP = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/responsible-list`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch list doctor
export const doctorListActiveDateState = atom({
  key: 'doctorListActiveDateState',
  default: [],
})
export const fetchDoctorListActiveDate = async (date, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/officers/doctor-list?status=active&activeDate=${date}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// add doctor
export const addAssignDoctor = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`assign-doctor`, input)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// answer request
export const AnswerAssignDoctor = async (id, status, cb) => {
  // status = accept, reject, done
  try {
    const { request } = API(true)
    const { data } = await request.patch(`/assign-doctor/${id}/${status}`)
    if (data.status) {
      cb(data.message, null)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// get assign by id
export const fetchOneAssignDoctor = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/assign-doctor/${id}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// get assign by id
export const updateAssignDoctor = async (id, input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`/assign-doctor/${id}`, input)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
