import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

export const fetchReportAllPatient = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/count`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
export const fetchReportCountNewPatient = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/count-new?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
export const fetchReportActivePatient = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/count-active`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportPatientGender = async (gender, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/count-gender/${gender}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportMonthlyVisits = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`appointments/summary/this-month?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportTotalIncome = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`orders/summary/totalincome?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportAge = async (params, cb) => {
  // params = filter=2022
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/patientsage?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportPatient = async (params, cb) => {
  // params = filter=year,month
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/patientstatus?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportTopLocation = async (params, cb) => {
  // params = filter=year,month
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/top-cities?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchLocationOfPatientVisitedDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/visit-patient-cities?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportTotalVisits = async (params, cb) => {
  // params = filter=year,month
  try {
    const { request } = API(true)
    const { data } = await request.get(`appointments/summary/totalvisit?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportTopSpender = async (params, cb) => {
  // params = filter=year,month
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/topspender?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportSpenderOpaDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/topspender-opa-detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportSpenderPpaDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/topspender-ppa-detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportActivePatients = async (params, cb) => {
  // params = filter=year,month
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/activeandinactive?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportVisitStatus = async (params, cb) => {
  // params = filter=year,month
  try {
    const { request } = API(true)
    const { data } = await request.get(`appointments/summary/totalvisitstatus?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportVisitStatusDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`appointments/summary/appointment-visit-status?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportDayVisit = async (params, cb) => {
  // params = filter=year,month
  try {
    const { request } = API(true)
    const { data } = await request.get(`appointments/summary/totaldayandtimevisit?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportFeedbackSurvey = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`feedback/summary-rate?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchCountLocationPatient = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/top-cities?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportFeedbackRefer = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`feedback/summary-rate?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportTransactionValue = async (params, cb) => {
  // params = filter=year,month
  try {
    const { request } = API(true)
    const { data } = await request.get(`orders/summary/transactionvalue?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportServices = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`orders/summary/services?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportServiceOpaDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`orders/summary/services-opa-detail?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
export const fetchReportServicePpaDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`orders/summary/services-ppa-detail?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchTotalRevenue = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`revenue/summary/totalrevenue?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const revenueServiceState = atom({
  key: 'revenueServiceState',
  default: [],
})

export const fetchRevenueService = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/revenue/services`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportRevenueValue = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`revenue/summary/revenuevalue?${params}`)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// detail
export const reportPatientDetailState = atom({
  key: 'reportPatientDetailState',
  default: { rows: [], meta: {} },
})
export const fetchReportPatientDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
export const fetchReportAppointmentDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`appointments/summary?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportFeedbackDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`feedback/summary?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportRevenueDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`revenue/summary?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportServiceDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`orders/summary?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportTopSpenderDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/topspender/detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportIncomeDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`revenue/summary?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchReportTransactionValueDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`orders/summary/transactionvalue/detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchChartNewPatient = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`patients/summary/chart/new-patient?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchChartVisitPatient = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/report/chart/patient-visits?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchChartPatientVisitDoctor = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/report/chart/patient-visit-doctors?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchChartPatientVisitLOS = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/report/chart/patient-visit-los?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchChartAgePatient = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/patients/chart/patientsage?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchChartAgePatientDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/patients/chart/patientsage/detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchChartNewPatientDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/patients/chart/newpatients/detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
export const fetchChartPatientVisitOPADetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/report/chart/patient-visit-opa-detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
export const fetchChartPatientVisitPPADetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/report/chart/patient-visit-ppa-detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchChartDoctorVisitedOpaDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/report/chart/patient-visit-doctors-opa-detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
export const fetchChartDoctorVisitedPpaDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/report/chart/patient-visit-doctors-ppa-detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchChartCategoryVisitedOPADetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/report/chart/patient-visit-los-opa-detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
export const fetchChartCategoryVisitedPPADetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/report/chart/patient-visit-los-ppa-detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchTotalIncomeOpaDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/revenue/totalrevenue/opa-detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
export const fetchTotalIncomePpaDetail = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`/revenue/totalrevenue/ppa-detail?${params}`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
