import { atom } from 'recoil'
import { API } from '../api/helpers'
import { errorResponse } from '../helper/getErrorResponse'

// fetch package
export const allPackageState = atom({
  key: 'allPackageState',
  default: { rows: [], meta: {} },
})

export const allPatientPackageState = atom({
  key: 'allPatientPackageState',
  default: { rows: [], meta: {} },
})

export const packageReportState = atom({
  key: 'packageReportState',
  default: { rows: [], meta: {} },
})

export const fetchAllPackage = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`packages?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchPackageList = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`running-package/list?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchPackageReport = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`running-package/package-report?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchPatientPackageList = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`running-package/patient?${params}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// delete package
export const deletePackage = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.delete(`packages/delete/${id}`)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// fetch treatment list
export const treatmentListState = atom({
  key: 'treatmentListState',
  default: [],
})
export const fetchTreatmentList = async (params, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`treatments/list`)

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

// create package
export const createPackage = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post('packages', input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const fetchOnePackage = async (id, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get(`packages/${id}`)
    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}
// update package
export const updatePackage = async (id, input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.patch(`packages/update/${id}`, input)

    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const checkHavePackage = async (input, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`running-package/patient-have-package`, input)

    if (data.status) {
      cb(data)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const syncPackage = async (payload, cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.post(`running-package/sync-patient`, payload)
    if (data.status) {
      cb(data.message)
    }
  } catch (error) {
    errorResponse(error, cb)
  }
}

export const exportData = async (payload, cb) => {
  try {
    const { request } = API(true)
    const response = await request.post(`running-package/export-report`, payload, {
      responseType: 'blob',
    })
    cb(response)
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' harap refresh website')
      }
    } else {
      alert(error.message)
    }
  }
}
