import { atom } from 'recoil'
import { API } from '../api/helpers'

// fetch religion
export const dataReligionState = atom({
  key: 'dataReligionState',
  default: [],
})
export const fetchDataReligion = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('general/religions')

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' harap refresh website')
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch identity type
export const dataIdentityTypeState = atom({
  key: 'dataIdentityTypeState',
  default: [],
})
export const fetchDataIdentityType = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('general/identitytipes')

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' harap refresh website')
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch education
export const dataEducationState = atom({
  key: 'dataEducationState',
  default: [],
})
export const fetchDataEducation = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('general/educations')

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' harap refresh website')
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch occupation
export const dataOccupationState = atom({
  key: 'dataOccupationState',
  default: [],
})
export const fetchDataOccupation = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('general/occupations')

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' harap refresh website')
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch marital status
export const dataMaritalStatusState = atom({
  key: 'dataMaritalStatusState',
  default: [],
})
export const fetchDataMaritalStatus = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('general/maritalstatuses')

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' harap refresh website')
      }
    } else {
      alert(error.message)
    }
  }
}

// fetch specialist
export const dataSpecialistState = atom({
  key: 'dataSpecialistState',
  default: [],
})
export const fetchDataSpecialist = async (cb) => {
  try {
    const { request } = API(true)
    const { data } = await request.get('general/specialists')

    if (data.status) {
      cb(data.data)
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        cb(null, { redirect: true })
      } else {
        alert(error.response.data.message + ' harap refresh website')
      }
    } else {
      alert(error.message)
    }
  }
}
